import React from "react";
class Loading extends React.Component {

    render() {
        return (
            <div className="loading-animation">
                <img src="/images/loading.svg" alt="Loading icon" loading="lazy" width="25" height="25"/>
            </div>
        )
    }
}
export default Loading;
