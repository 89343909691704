import React, {useState, useEffect} from "react";
import Internal from "../templates/internal/internal";
import {useNavigate} from "react-router-dom";
import LoadingPage from "../common/loadingpage";

const Account = () => {
    const navigate = useNavigate();
    const clientId = '711fkl1larvgvbiq676j49no6r';
    const redirect_uri = encodeURI(`${process.env.REACT_APP_CALLBACK_URL}auth/`);
    const billingEndpoint = `${process.env.REACT_APP_API_URL}billing`;

    const [userAuth, setuserAuth] = useState({
        isLoading: true,
        id_token: undefined,
        access_token: undefined,
        refresh_token: undefined,
        expires_in: undefined,
        token_type: undefined
    });

    const [loggedIn, setLoggedIn] = useState({
        isLoggedIn: false
    });

    const [authUrl, setAuthUrl] = useState({
        isLoading: true,
        url: undefined
    });

    const [manageSubscription, setManageSubscription] = useState({
        isLoading: false
    });

    useEffect(() => {

        // load the user auth url
        getAuthUrl();


        // Check if the user is already logged in
        let whispUserAuth = sessionStorage.getItem('whispUserAuth');
        if (whispUserAuth !== undefined && whispUserAuth !== null) {
            // User is logged in
            setuserAuth({...userAuth, whispUserAuth});
            setLoggedIn({...loggedIn, isLoggedIn: true});
        } else {
            // User is not logged in
            setLoggedIn({...loggedIn, isLoggedIn: false});
        }
    }, []);

    function getAuthUrl() {
        let postData = {
            "CallbackUrl": redirect_uri
        }
        fetch(`${process.env.REACT_APP_API_URL}auth-url`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "omit",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Origin": process.env.REACT_APP_CALLBACK_URL,
                "Access-Control-Request-Method": "POST"
            },
            redirect: "follow",
            referrer: "no-referrer",
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            })
            .then(data => {

                    if (data !== undefined) {
                        setAuthUrl({...authUrl,
                            isLoading: false,
                            url: data.Url
                        });
                    } else {
                        console.log(data);
                        console.log("errorType: " + data.ConnectionError);
                        console.log("errorMessage" + data.errorMessage);
                    }

            })
            .catch(error => console.error(`Fetch Error =\n`, error));
    }

    function handleUserLogout() {
        sessionStorage.removeItem('whispUserAuth');
        navigate('/');
    }

    function refreshUserTokens(callBackFunction = undefined) {
        //console.log('refreshUserTokens function called ');
        let userAuth = JSON.parse( sessionStorage.getItem('whispUserAuth') );
            let postData = {
                "grant_type": "refresh_token",
                "client_id": clientId,
                "refresh_token": userAuth.access_token,
                CallbackUrl: `${process.env.REACT_APP_CALLBACK_URL}auth/`
            }

            fetch(`${process.env.REACT_APP_API_URL}tokens`, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "omit",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "Origin": "http://localhost:3000/",
                    "Access-Control-Request-Method": "POST",
                    "Authorization" : userAuth.id_token
                },
                redirect: "follow",
                referrer: "no-referrer",
                body: JSON.stringify(postData)
            })
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    }
                })
                .then(data => {
                    if (data.errorType === undefined) {
                        let authResult = {
                            isLoading: false,
                            id_token: data.id_token,
                            access_token: data.access_token,
                            refresh_token: data.refresh_token,
                            expires_in: data.expires_in,
                            token_type: data.token_type
                        }
                        sessionStorage.setItem('whispUserAuth', JSON.stringify(authResult));
                        if (callBackFunction !== undefined && callBackFunction !== null) {
                            callBackFunction();
                        }
                    } else {
                        console.log(data);
                        console.log("errorType: " + data.ConnectionError);
                        console.log("errorMessage" + data.errorMessage);
                    }
                })
                .catch(error => console.error(`Fetch Error =\n`, error));

    }

    function handleManageSubscription() {
        setManageSubscription({...manageSubscription, isLoading: true});
        const userAuth = JSON.parse( sessionStorage.getItem('whispUserAuth') );
        let postData = {
            "AccessToken": userAuth.access_token
        }
        let response = fetch(billingEndpoint, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "omit",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Origin": "http://localhost:3000/",
                "Access-Control-Request-Method": "POST",
                "Authorization" : userAuth.id_token
            },
            redirect: "follow",
            referrer: "no-referrer",
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else if (response.status === 401) {
                    // passing in the current function so that the user action can continue seamlessly
                    refreshUserTokens(handleManageSubscription);
                }
            })
            .then(data => {
                // get the stripe URL and redirect the user
                if (data.url !== undefined && data.url !== null) {
                    window.location.href = data.url;
                }
            })
            .catch(error => {
                console.log('Something went wrong. Likely caused by API time out');
                console.log(error);
            });
    }

     function refreshUserTokens(callBackFunction) {
        let userAuth = JSON.parse( sessionStorage.getItem('whispUserAuth') );
            let postData = {
                RefreshToken: userAuth.refresh_token,
                CallbackUrl: `${process.env.REACT_APP_CALLBACK_URL}auth/`
            }
            fetch(`${process.env.REACT_APP_API_URL}refresh`, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "omit",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "Origin": "http://localhost:3000/",
                    "Access-Control-Request-Method": "POST",
                },
                redirect: "follow",
                referrer: "no-referrer",
                body: JSON.stringify(postData)
            })
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    }
                })
                .then(data => {
                    if (data.errorType === undefined) {
                        let authResult = {...userAuth,
                            isLoading: false,
                            id_token: data.id_token,
                            access_token: data.access_token,
                            expires_in: data.expires_in,
                            token_type: data.token_type
                        }
                        sessionStorage.setItem('whispUserAuth', JSON.stringify(authResult));
                        if (callBackFunction !== null)
                            callBackFunction();

                    } else {
                        console.log(data);
                        console.log("errorType: " + data.ConnectionError);
                        console.log("errorMessage" + data.errorMessage);
                    }
                })
                .catch(error => console.error(`Fetch Error =\n`, error));
    }

    return (
        <>
            <Internal>
                {(manageSubscription.isLoading)? (
                    <LoadingPage />
                ) : (
                <div className="tp-account">
                    <h1>Account</h1>
                    <div className="account-actions">
                        {(!loggedIn.isLoggedIn)? (
                            <div>
                                {(!authUrl.isLoading)? (
                                <a className={"btn btn-blue"} href={authUrl.url}>Login/Register</a>
                                ) : null}
                            </div>
                        ) : (
                            <>
                                {/*<div><div className={"btn btn-blue"} onClick={() => {refreshUserTokens(null)}}>Refresh Auth Tokens</div></div>*/}
                                <div><div className={"btn btn-blue"} onClick={handleManageSubscription}>Manage Subscription</div></div>
                                <div><span className={"btn btn-blue"} onClick={handleUserLogout}>Logout</span></div>
                            </>
                        )}
                    </div>
                </div>
                )}
            </Internal>
        </>
    )
}
export default Account;
