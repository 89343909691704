import React from "react";
class LoadingPage extends React.Component {

    render() {
        return (
            <div className="loading-animation">
                <div>
                <dotlottie-player autoplay loop mode="normal"
                                  src="/Whisp_Loader_Graphic.lottie"
                                  style={ {width: "320px"} }></dotlottie-player>
                    <div className="loading-text">Loading...</div>
                </div>
            </div>
        )
    }
}
export default LoadingPage;
