import React from "react";
import './generic.css';
import Footer from "../../common/Footer/footer";
class Generic extends React.Component {
    render() {
        return (
            <div className="generic-component-template">
                <div className="inner">
                    {this.props.children}
                </div>
                <Footer />
            </div>
        )
    }
}
export default Generic;
