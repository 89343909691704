import React from "react";
import Api from '../../Api';
import './article.css';
import FactCheck from "./factcheck/factcheck";
import Evidence from "./evidence/evidence";
import Generic from '../templates/generic/generic';
import Header from "../common/Header/header";
import LoadingPage from "../common/loadingpage";
import {Link} from "react-router-dom";
class Article extends React.Component {
    constructor() {
        super();
        this.state = {
            isLoading: true,
            isFactCheck: false,
            isEvidence: false,
            isError: false,
            useChatGPT: false,
            initialQuery: '',
            articleData: {},
            sentenceData: {},
            showSourceInfo: false,
            selectedClaimIndex: 0,
            selectedSentence: {},
            selectedClaim: {},
            cbMessage: '',
            gptUUID: ''
        }
        this.pollingDelay = 2000;
        this.invokeEndpoint = `${process.env.REACT_APP_API_URL}invoke`;
        this.handleSentenceClick = this.handleSentenceClick.bind(this);
        this.handleEvidenceClick = this.handleEvidenceClick.bind(this);
        this.handleReturnToArticle = this.handleReturnToArticle.bind(this);
        this.handleCloseEvidence = this.handleCloseEvidence.bind(this);
        this.handleNextSentenceClick = this.handleNextSentenceClick.bind(this);
        this.handlePrevSentenceClick = this.handlePrevSentenceClick.bind(this);
        this.handleNextClaimClick = this.handleNextClaimClick.bind(this);
        this.handlePrevClaimClick = this.handlePrevClaimClick.bind(this);
        this.handleSentenceClaimCheck = this.handleSentenceClaimCheck.bind(this);
        this.handleShowSourceInfo = this.handleShowSourceInfo.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleEvidenceItemCountIncrement = this.handleEvidenceItemCountIncrement.bind(this);
        this.handleInitialArticleLookup = this.handleInitialArticleLookup.bind(this);
        this.refreshUserTokens = this.refreshUserTokens.bind(this);
    }

    gptMessage = React.createRef();


    componentDidMount() {
        const websiteURL = localStorage.getItem('websiteURL');
        const useChatGPT = (localStorage.getItem('useChatGPT') === "true");

        this.setState({
            initialQuery: websiteURL,
            useChatGPT: useChatGPT
        })

        if (useChatGPT) {
            // ChatGPT lookup
            //this.handleChatGPTLookup(useChatGPT, websiteURL);
            //alert('usechategpt set in article');
        } else {
            this.handleInitialArticleLookup()
        }
    }

    refreshUserTokens(callBackFunction) {
        let userAuth = JSON.parse( sessionStorage.getItem('whispUserAuth') );
        let postData = {
            RefreshToken: userAuth.refresh_token
        }
        fetch(`${process.env.REACT_APP_API_URL}refresh`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "omit",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Origin": "http://localhost:3000/",
                "Access-Control-Request-Method": "POST",
            },
            redirect: "follow",
            referrer: "no-referrer",
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            })
            .then(data => {
                if (data.errorType === undefined) {
                    let authResult = {...userAuth,
                        isLoading: false,
                        id_token: data.id_token,
                        access_token: data.access_token,
                        expires_in: data.expires_in,
                        token_type: data.token_type
                    }
                    sessionStorage.setItem('whispUserAuth', JSON.stringify(authResult));
                    if (callBackFunction !== null)
                        callBackFunction();

                } else {
                    console.log(data);
                    console.log("errorType: " + data.ConnectionError);
                    console.log("errorMessage" + data.errorMessage);
                }
            })
            .catch(error => console.error(`Fetch Error =\n`, error));
    }



    async handleInitialArticleLookup(websiteURLOveride = undefined) {
        const websiteURL = (websiteURLOveride !== undefined)? websiteURLOveride : localStorage.getItem('websiteURL');
        const userAuth = JSON.parse( sessionStorage.getItem('whispUserAuth') );
        let postData = {
            "RequestType": "Article",
            "RequestBody": {
                "Url": websiteURL
            }
        }

        let response = await fetch(this.invokeEndpoint, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "omit",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Origin": process.env.REACT_APP_CALLBACK_URL,
                "Access-Control-Request-Method": "POST",
                "Authorization" : userAuth.id_token
            },
            redirect: "follow",
            referrer: "no-referrer",
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else if (response.status === 401) {
                    // passing in the current function so that the user action can continue seamlessly
                    this.refreshUserTokens(this.handleInitialArticleLookup(websiteURL));
                }
            })
            .then(data => {
                // statusCode 202 is returned while the API is still processing the request
                if (data.statusCode !== undefined && data.statusCode === 202) {
                    // wait 2 seconds before calling this function again
                    setTimeout(() => {
                        this.handleInitialArticleLookup(websiteURL);
                    }, this.pollingDelay)
                } else {
                    if (data !== undefined) {
                        this.setState({
                            articleData: data,
                            sentenceData: data.analysis,
                            isLoading: false,
                            isError: false
                        });
                        // Set default sentence selected
                        for (let i = 0; i < data.analysis.length; i++) {
                            let found = false;
                            // check if there are claims
                            if (data.analysis[i].claims !== null) {
                                // check that there is at least one claim
                                if (data.analysis[i].claims.length > 0) {
                                    // loop through the claims
                                    for (let ii = 0; ii < data.analysis[i].claims.length; ii++) {
                                        // check if the claim has at already one "entail" or "refute"
                                        if (data.analysis[i].claims[ii].evidence !== null &&
                                            data.analysis[i].claims[ii].evidence !== undefined &&
                                            (data.analysis[i].claims[ii].evidence.n_entail > 0 || data.analysis[i].claims[ii].evidence.n_refute > 0)) {

                                            this.handleSentenceClick(data.analysis[i]);
                                            found = true;
                                            break;
                                        }
                                    }
                                }
                            }
                            if (found) {
                                break;
                            }
                        }
                    } else {
                        // API Timeout
                        console.log('data is undefined. Likely caused by API time out');
                        this.setState({
                            isError: true
                        })
                    }
                }
            })
            .catch(error => {
                console.log('Something went wrong. Likely caused by API time out');
                console.log(error);
                this.setState({
                    isError: true
                })
            });

    }

    handleReturnToArticle() {
        this.setState({
            isFactCheck: false,
            selectedSentence: {}
        });
    }

    handleSentenceClick (sentence) {
        this.setState({
            isFactCheck: false,
            isEvidence: false
        });
        this.setState({
            selectedSentence: sentence
        });
        this.setState({
            isFactCheck: true
        });
        // scroll the user to the start of the factcheck component
        /*let factcheckComponent = document.getElementsByClassName('factcheck-container');
        if (factcheckComponent !== undefined && factcheckComponent[0] !== undefined) {
            factcheckComponent[0].scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
            });
        }*/
    }

    handleEvidenceClick (selectedClaim, selectedClaimIndex) {
        this.setState({
            isEvidence: true,
            selectedClaim: selectedClaim,
            selectedClaimIndex: selectedClaimIndex
        });
    }

    handleCloseEvidence () {
        this.setState({
            isEvidence: false,
            selectedClaim: {},
            selectedClaimIndex: 0
        });
    }

    handlePrevSentenceClick () {
        let index = this.state.articleData.analysis.findIndex( (sentence) => sentence.sid ===  this.state.selectedSentence.sid );
        let prevItem = this.state.articleData.analysis[(index - 1)];
        this.setState({
            isFactCheck: false
        });
        this.setState({
            selectedSentence: prevItem,
        });
        this.setState({
            isFactCheck: true
        });
    }

    handleNextSentenceClick () {
        let index = this.state.articleData.analysis.findIndex( (sentence) => sentence.sid ===  this.state.selectedSentence.sid );
        let nextItem = this.state.articleData.analysis[(index + 1)];
        this.setState({
            isFactCheck: false,
            isEvidence: false
        });
        this.setState({
            selectedSentence: nextItem,
            isFactCheck: true
        });
    }

    handleEvidenceItemCountIncrement() {
        let currentEvidenceItemCount = this.state.selectedClaimIndex
        this.setState({
            selectedClaimIndex: currentEvidenceItemCount++
        })
    }

    handleNextClaimClick() {
        let nextClaimIndex = this.state.selectedClaimIndex;
        if (this.state.selectedSentence.claims.length > (nextClaimIndex + 1)) {
            // check if next claim has evidence
            if (this.state.selectedSentence.claims[nextClaimIndex + 1].evidence !== null &&
                ( (this.state.selectedSentence.claims[nextClaimIndex + 1].evidence.n_entail + this.state.selectedSentence.claims[nextClaimIndex + 1].evidence.n_refute) > 0 )
            ) {
                nextClaimIndex = (nextClaimIndex + 1);
            } else {
                // look for next claim with evidence
                for (let i=nextClaimIndex; i < this.state.selectedSentence.claims.length; i++) {
                    if (this.state.selectedSentence.claims[i].evidence !== null &&
                        ( (this.state.selectedSentence.claims[i].evidence.n_entail + this.state.selectedSentence.claims[i].evidence.n_refute) > 0 )
                    ) {
                        nextClaimIndex = i;
                        break;
                    }
                }
            }
        }

        this.setState({
            isEvidence: false,
            isLoading: true,
            selectedClaim: this.state.selectedSentence.claims[nextClaimIndex],
            selectedClaimIndex: nextClaimIndex
        })

        this.setState({
            isEvidence: true,
            isLoading: false
        })

    }

    handlePrevClaimClick() {
        let prevClaimIndex = this.state.selectedClaimIndex;
        if (this.state.selectedSentence.claims.length > (prevClaimIndex + 1)) {
            // check if next claim has evidence
            if (this.state.selectedSentence.claims[prevClaimIndex - 1].evidence !== null &&
                ( (this.state.selectedSentence.claims[prevClaimIndex - 1].evidence.n_entail + this.state.selectedSentence.claims[prevClaimIndex - 1].evidence.n_refute) > 0 )
            ) {
                prevClaimIndex = (prevClaimIndex - 1);
            } else {
                // look for next claim with evidence
                for (let i=this.state.selectedSentence.claims.length; (i > 0 && i > prevClaimIndex); i--) {
                    if (this.state.selectedSentence.claims[i].evidence !== null &&
                        ( (this.state.selectedSentence.claims[i].evidence.n_entail + this.state.selectedSentence.claims[i].evidence.n_refute) > 0 )
                    ) {
                        prevClaimIndex = i;
                        break;
                    }
                }
            }
        }

        this.setState({
            isEvidence: false,
            isLoading: true,
            selectedClaim: this.state.selectedSentence.claims[prevClaimIndex],
            selectedClaimIndex: prevClaimIndex
        })

        this.setState({
            isEvidence: true,
            isLoading: false
        })

    }

    handleSentenceClaimCheck(sentenceItem) {
        if (sentenceItem !== undefined) {
            let sentenceClasses = '';

            if (this.state.useChatGPT){
                // check if selected or not
                if (this.state.selectedSentence.content.content === sentenceItem.content.content) {
                    sentenceClasses += "sentence-item selected";
                } else {
                    sentenceClasses += "sentence-item";
                }

                // Calculate how many Positive and Negative claims based on the evidence entail,refute
                let totalPositiveClaims = 0;
                let totalNegativeClaims = 0;

                if (sentenceItem.claims !== null) {
                    totalPositiveClaims = sentenceItem.claims.filter(claim => {
                        if (claim.evidence !== null && claim.evidence.n_entail > claim.evidence.n_refute) {
                            return claim;
                        } else {
                            return null;
                        }
                    }).length;
                    totalNegativeClaims = sentenceItem.claims.filter(claim => {
                        if (claim.evidence !== null && claim.evidence.n_entail < claim.evidence.n_refute) {
                            return claim;
                        } else {
                            return null;
                        }
                    }).length;
                }

                // mostly entail claims
                if (totalPositiveClaims > totalNegativeClaims) {
                    sentenceClasses += " mostly-entail ";
                }
                // mostly refute claims
                if (totalPositiveClaims < totalNegativeClaims) {
                    sentenceClasses += " mostly-refute ";
                }
                // passes the CFS check but has no claims with evidence
                if (totalPositiveClaims === 0 && totalNegativeClaims === 0) {
                    sentenceClasses += " unimportant ";
                }

            } else {

                // check if selected or not
                if (this.state.selectedSentence.sid === sentenceItem.sid) {
                    sentenceClasses += "sentence-item selected";
                } else {
                    sentenceClasses += "sentence-item";
                }

                let totalPositiveClaims = 0;
                let totalNegativeClaims = 0;

                // Calculate how many Positive and Negative claims based on the evidence entail,refute
                if (sentenceItem.claims !== null) {
                    totalPositiveClaims = sentenceItem.claims.filter(claim => {
                        if (claim.evidence !== null && claim.evidence.n_entail > claim.evidence.n_refute) {
                            return claim;
                        } else {
                            return null;
                        }
                    }).length;

                    totalNegativeClaims = sentenceItem.claims.filter(claim => {
                        if (claim.evidence !== null && claim.evidence.n_entail < claim.evidence.n_refute) {
                            return claim;
                        } else {
                            return null;
                        }
                    }).length;
                }

                // mostly entail claims
                if (totalPositiveClaims > totalNegativeClaims) {
                    sentenceClasses += " mostly-entail ";
                }
                // mostly refute claims
                if (totalPositiveClaims < totalNegativeClaims) {
                    sentenceClasses += " mostly-refute ";
                }
                // passes the CFS check but has no claims with evidence
                if (totalPositiveClaims === 0 && totalNegativeClaims === 0) {
                    sentenceClasses += " unimportant ";
                }

            }
            return sentenceClasses;
        } else {
            return '';
        }
    }

    handleShowSourceInfo() {
        this.setState({
            showSourceInfo: !this.state.showSourceInfo
        })
    }
    handleEdit() {
        //alert('Handle Edit functionality')
    }



    render() {
        const {isLoading, useChatGPT, articleData, sentenceData, isFactCheck, selectedSentence, isEvidence, selectedClaim, showSourceInfo} = this.state;

        if (!isLoading) {
            return (
                <Generic>
                    <div className="article-component">
                        <div className={(useChatGPT)? "article-data layout-chatgpt" : "article-data"}>

                            <div className="content">

                                <div className="left-column ">
                                    <Header showNewSearch={true} />
                                    <div className="main-content">
                                        {(useChatGPT)? null : (
                                            <div className="action-bar">
                                                <div className="top-bar">
                                                    <div className="article-logo">
                                                        {(articleData.site_logo !== null && articleData.site_logo !== undefined)? (
                                                            <img src={articleData.site_logo} alt={articleData.site_name}/>
                                                        ) : null}
                                                    </div>
                                                    <div onClick={this.handleShowSourceInfo} className="source-info-toggle">
                                                        <div>Source Info</div>
                                                        {(showSourceInfo)? (
                                                            <img src="/images/icon-collapse.svg" alt="Hide Source info" loading="lazy" width="24" height="22"/>
                                                        ): (
                                                            <img src="/images/icon-expand.svg" alt="Show Source info" loading="lazy" width="24" height="22"/>
                                                        )}
                                                    </div>
                                                </div>
                                                {(showSourceInfo)? (
                                                    <div className="source-info">
                                                        {(articleData.date_published !== undefined)? (
                                                            <div className="si-item">
                                                                <div className="si-title">Published:</div>
                                                                {articleData.date_published}
                                                            </div>
                                                        ) :  null}

                                                        {(articleData.authors !== undefined)? (
                                                            <div className="si-item">
                                                                <div className="si-title">Author:</div>
                                                                {articleData.authors}
                                                            </div>
                                                        ) : null}

                                                        {(articleData.site_name !== undefined)? (
                                                            <div className="si-item">
                                                                <div className="si-title">Source name &amp; URL:</div>
                                                                {(articleData.url!== undefined)? (
                                                                    <a href={articleData.url} target="_blank" rel="nofollow noreferrer">{articleData.site_name}</a>
                                                                ) : (
                                                                    articleData.site_name
                                                                ) }
                                                            </div>
                                                        ) : null}

                                                        {(articleData.description !== undefined && articleData.description.content !== undefined)? (
                                                            <div className="si-item description">
                                                                <div className="si-title">Description:</div>
                                                                {articleData.description.content}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                ) : null }
                                            </div>
                                        )}

                                        <div className="edit-bar">

                                        </div>

                                        <div className="sticky-scroll-box">
                                            <div className="article-title">
                                                {(articleData.title !== null && articleData.title !== undefined)? articleData.title.content : null}
                                            </div>
                                            <div className="article-content">
                                                {sentenceData.map(sentenceItem => (

                                                    (useChatGPT)? (
                                                        <div>
                                                    <span onClick={() => this.handleSentenceClick(sentenceItem)}
                                                          className={this.handleSentenceClaimCheck(sentenceItem)}
                                                          key={sentenceItem.content.content}>
                                                        {sentenceItem.content.content}
                                                    </span>
                                                        </div>
                                                    ) : (
                                                        <span onClick={() => this.handleSentenceClick(sentenceItem)}
                                                              className={this.handleSentenceClaimCheck(sentenceItem)}
                                                              key={sentenceItem.sid}>
                                                        {sentenceItem.text}
                                                    </span>
                                                    )

                                                ))}
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                { (isFactCheck === true && isEvidence === false) ? (
                                    <div className="right-column factcheck-container">
                                        { (isFactCheck === true && useChatGPT === false) ? (
                                            <div className="actions">
                                                <div className="sentence-toggle">
                                                    <div className="btn-container">
                                                        { (sentenceData.findIndex( (sentence) => sentence.sid ===  this.state.selectedSentence.sid ) > 0) ? (
                                                            <div className="btn prev enabled" onClick={this.handlePrevSentenceClick}>
                                                                <img src="/images/arrow-small-back-white.svg" width="12" height="20" loading="lazy" alt="Back arrow" />
                                                            </div>
                                                        ) : (
                                                            <div className="btn prev" >
                                                                <img src="/images/arrow-small-back-white-disabled.svg" width="12" height="20" loading="lazy" alt="Back arrow disabled" />
                                                            </div>
                                                        ) }
                                                        <span>Sentences</span>
                                                        { (sentenceData.findIndex( (sentence) => sentence.sid ===  this.state.selectedSentence.sid ) < (sentenceData.length - 1) )  ? (
                                                            <div className="btn next enabled" onClick={this.handleNextSentenceClick}>
                                                                <img src="/images/arrow-small-next-white.svg" width="12" height="20" loading="lazy" alt="Next arrow" />
                                                            </div>
                                                        ) : (
                                                            <div className="btn next">
                                                                <img src="/images/arrow_small_next_disabled.svg" width="12" height="20" loading="lazy" alt="Next arrow disabled" />
                                                            </div>
                                                        ) }
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null }
                                        <FactCheck
                                            factCheckItem={selectedSentence}
                                            handleEvidenceClick={this.handleEvidenceClick}
                                            handleReturnToArticle={() => this.handleReturnToArticle()}
                                            chatGPTMode={useChatGPT}
                                        />
                                    </div>
                                ) : null }

                                { isEvidence ? (
                                    <div className="right-column evidence-container">
                                        <Evidence
                                            evidenceItem={selectedClaim.evidence}
                                            selectedClaim={selectedClaim}
                                            handleNextClaimClick={this.handleNextClaimClick}
                                            handlePrevClaimClick={this.handlePrevClaimClick}
                                            handleCloseEvidence={this.handleCloseEvidence}
                                            handlePrevSentenceClick={this.handlePrevSentenceClick}
                                            handleNextSentenceClick={this.handleNextSentenceClick}
                                            selectedClaimIndex={(this.state.selectedClaimIndex + 1)}
                                            totalClaims={this.state.selectedSentence.claims.length}
                                            currentSentenceNumber={ (sentenceData.findIndex( (sentence) => sentence.sid ===  this.state.selectedSentence.sid )) }
                                            totalSentences={ (sentenceData.length - 1) }
                                            chatGPTMode={useChatGPT}
                                        />
                                    </div>
                                ) : null }

                            </div>
                        </div>
                    </div>
                </Generic>
            )
        } else {
            return (
                <Generic>
                    {(this.state.isError)? (
                        <div className="error-modal">
                            <div className="em-inner">
                                We are currently experiencing higher demand than usual.<br />
                                Please try again in a few minutes.<br />
                                <Link to={"/"} >Return to the home page</Link>
                            </div>
                        </div>
                    ) : null }
                    <LoadingPage />
                </Generic>
            )
        }
    }
}
export default Article;
