import React from "react";
import './evidence.css';
import Generic from "../../templates/generic/generic";
import Loading from "../../common/loading";
class Evidence extends React.Component {
    constructor() {
        super();
        this.state = {
            isLoading: true,
            showSourceUrl: false,
            showSourceDescription: false,
            showEntailTab: false,
            showRefuteTab: false,
        }
        this.handleShowSourceDescription = this.handleShowSourceDescription.bind(this);
        this.handleShowSourceURL = this.handleShowSourceURL.bind(this);
        this.handleShowEntailTab = this.handleShowEntailTab.bind(this);
        this.handleShowRefuteTab = this.handleShowRefuteTab.bind(this);
        this.handleVerdictCheck = this.handleVerdictCheck.bind(this);
        this.getSelectedClaim = this.getSelectedClaim.bind(this);
        this.getClaims = this.getClaims.bind(this);
        this.getCertaintyLevel = this.getCertaintyLevel.bind(this);
        this.handleClaimUnderline = this.handleClaimUnderline.bind(this);
    }

    componentDidMount() {
        let entailTab = true;
        let refuteTab = false;

        if (this.props.evidenceItem !== null && this.props.evidenceItem !== undefined) {
            if (this.props.evidenceItem.entail !== null && this.props.evidenceItem.entail !== undefined) {
                if (this.props.evidenceItem.entail.length < this.props.evidenceItem.refute.length) {
                    entailTab = false;
                    refuteTab = true;
                }
            }
        }
        this.setState({
            isLoading: false,
            showEntailTab: entailTab,
            showRefuteTab: refuteTab
        });
    }

    getSelectedClaim() {
        return this.props.evidenceItem.entail.concat(this.props.evidenceItem.refute)[0];
    }
    getClaims() {
        return this.props.evidenceItem.entail.concat(this.props.evidenceItem.refute);
    }

    handleShowSourceURL (sourceurlid) {
        let sourceurl = document.getElementById(sourceurlid);
        let descriptionItems = document.getElementsByClassName('description');
        for (let i=0;i<descriptionItems.length;i++) {
            descriptionItems[i].classList.remove('active');
        }

        if (sourceurl !== undefined && sourceurl !== null) {
            sourceurl.classList.toggle("active");
        }
    }
    handleShowSourceDescription (descriptionid) {
        let description = document.getElementById(descriptionid);
        let sourceUrlItems = document.getElementsByClassName('sourceurl');
        for (let i=0;i<sourceUrlItems.length;i++) {
            sourceUrlItems[i].classList.remove('active');
        }

        if (description !== undefined && description !== null) {
            description.classList.toggle("active");
        }
    }

    handleShowEntailTab() {
        this.setState({
            showEntailTab: true,
            showRefuteTab: false
        })
    }

    handleShowRefuteTab() {
        this.setState({
            showEntailTab: false,
            showRefuteTab: true
        })
    }

    handleVerdictCheck (claim) {
        return (claim.entail.length > claim.refute.length);
    }

    getCertaintyLevel() {
        let certaintyLevel = "Low";
        let totalPositiveClaims = this.props.evidenceItem.entail.length;
        let totalNegativeClaims = this.props.evidenceItem.refute.length;

        // check if there are both positive and negative
        if ( totalPositiveClaims > 0 && totalNegativeClaims > 0) {
            let percentage = 0;
            if (totalPositiveClaims > totalNegativeClaims) {
                percentage = (totalPositiveClaims / totalNegativeClaims) * 100;
            } else {
                percentage = (totalNegativeClaims / totalPositiveClaims) * 100;
            }
            //console.log(percentage);
            if (percentage > 76) {
                certaintyLevel = "High";
            } else if (percentage > 61) {
                certaintyLevel = "Medium";
            }
            // defaults to low if below 61%
        } else if ( totalPositiveClaims > 0 || totalNegativeClaims > 0 ){
            // gets set to "High" if either one of them is greate than 1 because there is only one type
            certaintyLevel = "High";
        }

        return certaintyLevel;
    }

    handleClaimUnderline(claim) {
        let sentenceClasses = " claim-text ";
        if (claim.checkworthy !== undefined && claim.checkworthy !== null && claim.checkworthy.score) {
            // Check if CFS
            if ( (claim.checkworthy.score.CFS * 100) > 50 ) {

                if (claim.evidence !== null) {
                    let totalPositiveClaims = claim.evidence.n_entail;

                    let totalNegativeClaims = claim.evidence.n_refute;

                    // mostly entail claims
                    if (totalPositiveClaims > totalNegativeClaims) {
                        sentenceClasses += " mostly-entail ";
                    }
                    // mostly refute claims
                    if (totalPositiveClaims < totalNegativeClaims) {
                        sentenceClasses += " mostly-refute ";
                    }
                }
            }
        }
        return sentenceClasses;
    }

    render() {
        const {isLoading, showSourceDescription, showSourceUrl, showEntailTab, showRefuteTab} = this.state;
        if (!isLoading) {
            return (
                <div className="evidence-component">

                    {(this.props.chatGPTMode === false)? (
                    <div className="actions">
                        <div className="btn-container">

                            { (this.props.currentSentenceNumber > 0) ? (
                            <div className="btn prev enabled" onClick={this.props.handlePrevSentenceClick}>
                                <img src="/images/arrow-small-back-white.svg" width="12" height="20" loading="lazy" alt="Back arrow" />
                            </div>
                            ) : (
                                <div className="btn prev" >
                                    <img src="/images/arrow-small-back-white-disabled.svg" width="12" height="20" loading="lazy" alt="Back arrow disabled" />
                                </div>
                            ) }

                            <span>Sentences</span>

                            { (this.props.currentSentenceNumber  < this.props.totalSentences )  ? (
                            <div className="btn next enabled" onClick={this.props.handleNextSentenceClick}>
                                <img src="/images/arrow-small-next-white.svg" width="12" height="20" loading="lazy" alt="Next arrow" />
                            </div>
                            ) : (
                                <div className="btn next">
                                    <img src="/images/arrow_small_next_disabled.svg" width="12" height="20" loading="lazy" alt="Next arrow disabled" />
                                </div>
                            ) }
                        </div>
                    </div>
                    ) : null}

                    <div className="evidence-data">
                        <div className="action-bar-white">
                            <div className="btn-close" onClick={this.props.handleCloseEvidence}>
                                <div><img src="/images/arrow-small-back-white.svg" width="12" height="20" loading="lazy" alt="Back to Original Sentence" /></div>
                                <div>Back to Original Sentence</div>
                            </div>
                            {(this.props.chatGPTMode === false)? (
                            <div className="claim-navigation">
                                { (this.props.selectedClaimIndex > 1) ? (
                                    <div className="btn prev" onClick={this.props.handlePrevClaimClick}>
                                        <img src="/images/arrow-small-back-white.svg" width="12" height="20" loading="lazy" alt="Back arrow" />
                                    </div>
                                ) : (
                                    <div className="btn prev">
                                        <img src="/images/arrow-small-back-white-disabled.svg" width="12" height="20" loading="lazy" alt="Back arrow disabled" />
                                    </div>
                                ) }
                                <span>Next Claim</span>
                                { ( this.props.selectedClaimIndex < this.props.totalClaims )  ? (
                                    <div className="btn next" onClick={this.props.handleNextClaimClick}>
                                        <img src="/images/arrow-small-next-white.svg" width="12" height="20" loading="lazy" alt="Next arrow" />
                                    </div>
                                ) : (
                                    <div className="btn next">
                                        <img src="/images/arrow_small_next_disabled.svg" width="12" height="20" loading="lazy" alt="Next arrow disabled" />
                                    </div>
                                ) }
                            </div>
                            ) : null }
                        </div>

                        <div className="claim-summary">
                            <img className="claim-line-icon" src="/images/line-claim-susbset.svg" alt="Line claim subset icon" height="31" width="20" loading="lazy" />
                            <div className="column-left">
                                <div className="claim-number">
                                    Claim {this.props.selectedClaimIndex} of {this.props.totalClaims}:<br/>
                                </div>
                                <div className="claim">
                                    <span className={this.handleClaimUnderline(this.props.selectedClaim)}>{this.props.selectedClaim.text}</span>
                                </div>
                                <div className="claim-info">
                                    <div className="info-item">
                                        Verdict:<br />
                                        <span className="value">{ ( this.handleVerdictCheck(this.props.evidenceItem) )? 'Agree' : 'Disagree' }</span>
                                    </div>
                                    <div className="info-item">
                                        Certainty Level:<br />
                                        <span className="value">
                                            <span>{this.getCertaintyLevel()}</span>
                                        </span>
                                    </div>
                                    <div className="info-item">
                                        Claims:<br />
                                        <span className="value">
                                            { ( this.handleVerdictCheck(this.props.evidenceItem) )? (
                                                <span>{this.props.evidenceItem.entail.length} / {(this.props.evidenceItem.refute.length + this.props.evidenceItem.entail.length)} Agree</span>
                                            ) : (
                                                <span>{this.props.evidenceItem.refute.length} / {(this.props.evidenceItem.refute.length + this.props.evidenceItem.entail.length)} Disagree</span>
                                            ) }
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="column-right">
                                <div className="progress-container">
                                    {(this.props.evidenceItem !== null && (this.props.evidenceItem.n_refute > 0 || this.props.evidenceItem.n_entail > 0 ))? (
                                    <>
                                        <div className="progress-bar progress-cfs refute" style={{ 'background' : 'radial-gradient(closest-side, #1F2937 79%, transparent 82% 100%),' + 'conic-gradient(#FE735A calc(' + ((this.props.evidenceItem.refute.length / (this.props.evidenceItem.refute.length + this.props.evidenceItem.entail.length)) * 100) + ' * 1%), #616872 0)' }}>
                                            <progress className="loading-circle-cfs" value={(this.props.evidenceItem.refute.length / (this.props.evidenceItem.refute.length + this.props.evidenceItem.entail.length)) * 100} min="0" max="100">{(this.props.evidenceItem.refute.length / (this.props.evidenceItem.refute.length + this.props.evidenceItem.entail.length)) * 100}%</progress>
                                        </div>
                                        <div className="progress-bar progress-nfs entail" style={{ 'background' : 'radial-gradient(closest-side, #1F2937 79%, transparent 82% 100%),' + 'conic-gradient(#5abd60 calc(' + ( (this.props.evidenceItem.entail.length / (this.props.evidenceItem.refute.length + this.props.evidenceItem.entail.length)) * 100) + ' * 1%), #616872 0)' }}>
                                            <progress className="loading-circle-cfs" value={(this.props.evidenceItem.entail.length / (this.props.evidenceItem.refute.length + this.props.evidenceItem.entail.length)) * 100} min="0" max="100">{ (this.props.evidenceItem.entail.length / (this.props.evidenceItem.refute.length + this.props.evidenceItem.entail.length)) * 100 }%</progress>
                                        </div>
                                    </>
                                    ) : null }
                                </div>
                                <div className="hide-evidence" onClick={this.props.handleCloseEvidence}>
                                    <img src="/images/icon_hide_evidence_white.svg" alt="Hide evidence" width="22" height="22" loading="lazy" />
                                    <span>Hide Evidence</span>
                                </div>
                            </div>

                        </div>

                        <div className="claim-counter">Claim { this.props.selectedClaimIndex } Evidence:</div>

                        <div className="tabs">
                            <div className={"tab-heading aggree " + ((showEntailTab)? "active":"")} onClick={this.handleShowEntailTab}>
                                <span>Evidence in agreement ({this.props.evidenceItem.entail.length} / {(this.props.evidenceItem.entail.length + this.props.evidenceItem.refute.length)})</span>
                                <img src="/images/icon_dropdown.svg" alt="dropdown icon" width="15" height="8" loading="lazy" />
                            </div>
                            <div className={"tab-heading disagree " + ((showRefuteTab)? "active":"")} onClick={this.handleShowRefuteTab}>
                                <span>Evidence against ({this.props.evidenceItem.refute.length} / {(this.props.evidenceItem.entail.length + this.props.evidenceItem.refute.length)})</span>
                                <img src="/images/icon_dropdown.svg" alt="dropdown icon" width="15" height="8" loading="lazy" />
                            </div>
                        </div>

                        {(showEntailTab)? (
                            (this.props.evidenceItem.entail.length > 0)? (
                                <div className="tab-content entail">
                                    {this.props.evidenceItem.entail.map(entailItem => (
                                        <div key={entailItem.claim_id} className="item">
                                            <div className="item-top-bar">
                                                {(entailItem.parent_data !== undefined)? (
                                                <img src={entailItem.parent_data.logo} alt={entailItem.parent_data.site_name}/>
                                                ) : null}
                                                <div className="certainty-container">
                                                    <div className="certainty-level">
                                                        <span className="cl-label">Certainty level:</span><br />
                                                        { (entailItem.entailment !== undefined)? (
                                                            <>
                                                                {( ( entailItem.entailment.entropy < 0.75 )? (
                                                                    <span className="cl-value">High</span>
                                                                ) : ( entailItem.entailment.entropy < 1.25 )? (
                                                                    <span className="cl-value">Medium</span>
                                                                ): (
                                                                    <span className="cl-value">Low</span>
                                                                ) )}
                                                            </>
                                                        ) : null }
                                                    </div>
                                                    <div className="certainty-level-icon">
                                                        { (entailItem.entailment !== undefined)? (
                                                            <>
                                                                {( ( entailItem.entailment.entropy < 0.75 )? (
                                                                    <img src="/images/Icon_Certainty_High.svg" alt="High certainty" width="26" height="22" />
                                                                ) : ( entailItem.entailment.entropy < 1.25 )? (
                                                                    <img src="/images/Icon_Certainty_Medium.svg" alt="Medium certainty" width="26" height="22" />
                                                                ): (
                                                                    <img src="/images/Icon_Certainty_Low.svg" alt="Low certainty" width="26" height="22" />
                                                                ) )}
                                                            </>
                                                        ) : null }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="item-claim">
                                                <span className="item-count">{this.props.evidenceItem.entail.findIndex( (claim) => claim.claim_id ===  entailItem.claim_id ) + 1}/{this.props.evidenceItem.n_entail}. </span>
                                                <span className="item-claim-text">{entailItem.claim_text}</span>
                                            </div>

                                            {
                                                // entail item
                                            }
                                            <div className="info-container">
                                                <div className="info">
                                                    <div className="info-item">
                                                        <span className="ii-title">Source name:</span><br />
                                                        <span className="no-link">{entailItem.parent_data.site_name}</span>
                                                    </div>

                                                    {(entailItem.parent_data.article_url !== undefined)? (
                                                    <div className="info-item">
                                                        <span className="ii-title">Source:</span> <br />
                                                        <span className="link" onClick={() => this.handleShowSourceURL("sourceurl-" + entailItem.claim_id)}>View source (URL)</span>
                                                    </div>
                                                    ) : null }

                                                    <div className="info-item">
                                                        <span className="ii-title">Description:</span> <br />
                                                        <span className="link" onClick={() => this.handleShowSourceDescription("description-" + entailItem.claim_id)}>View</span>
                                                    </div>
                                                </div>
                                                <div className="more-info">

                                                    <div id={"description-" + entailItem.claim_id} className="description">
                                                        <div className="text-background">
                                                            {entailItem.parent_data.article_description}
                                                        </div>
                                                        <div className="tooltip-arrow-container">
                                                            <img className="tooltip-arrow" src="/images/tooltip-arrow.png" alt="tooltip arrow" />
                                                        </div>
                                                    </div>

                                                    <div id={"sourceurl-" + entailItem.claim_id} className="sourceurl">
                                                        <div className="text-background">
                                                            <a href={entailItem.parent_data.article_url} target="_blank" rel="noreferrer nofollow" >{entailItem.parent_data.article_url}</a>
                                                        </div>
                                                        <div className="tooltip-arrow-container">
                                                            <img className="tooltip-arrow" src="/images/tooltip-arrow.png" alt="tooltip arrow" />
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="tab-content entail">
                                    <div className="item no-evidence">No evidence found</div>
                                </div>
                            )
                        ) : null}

                        {(showRefuteTab)? (
                            (this.props.evidenceItem.refute.length > 0)? (
                                <div className="tab-content refute">
                                    {this.props.evidenceItem.refute.map(entailItem => (
                                        <div key={entailItem.claim_id} className="item">
                                            <div className="item-top-bar">
                                                <img src={entailItem.parent_data.logo} alt={entailItem.parent_data.site_name}/>
                                                <div className="certainty-container">
                                                    <div className="certainty-level">
                                                        <span className="cl-label">Certainty level:</span><br />
                                                        { (entailItem.entailment !== undefined)? (
                                                            <>
                                                                {( ( entailItem.entailment.entropy < 0.75 )? (
                                                                    <span className="cl-value">High</span>
                                                                ) : ( entailItem.entailment.entropy < 1.25 )? (
                                                                    <span className="cl-value">Medium</span>
                                                                ): (
                                                                    <span className="cl-value">Low</span>
                                                                ) )}
                                                            </>
                                                        ) : null }
                                                    </div>
                                                    <div className="certainty-level-icon">

                                                        { (entailItem.entailment !== undefined)? (
                                                            <>
                                                                {( ( entailItem.entailment.entropy < 0.75 )? (
                                                                    <img src="/images/Icon_Certainty_High.svg" alt="High certainty" width="26" height="22" />
                                                                ) : ( entailItem.entailment.entropy < 1.25 )? (
                                                                    <img src="/images/Icon_Certainty_Medium.svg" alt="Medium certainty" width="26" height="22" />
                                                                ): (
                                                                    <img src="/images/Icon_Certainty_Low.svg" alt="Low certainty" width="26" height="22" />
                                                                ) )}
                                                            </>
                                                        ) : null }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="item-claim">
                                                <span className="item-count">{this.props.evidenceItem.refute.findIndex( (claim) => claim.claim_id ===  entailItem.claim_id ) + 1}/{this.props.evidenceItem.n_refute}. </span>
                                                <span className="item-claim-text">{entailItem.claim_text}</span>
                                            </div>

                                            {
                                                // refute item
                                            }
                                            <div className="info-container">
                                                <div className="info">
                                                    <div className="info-item">
                                                        <span className="ii-title">Source name:</span><br />
                                                        <span className="no-link">{entailItem.parent_data.site_name}</span>
                                                    </div>

                                                    {(entailItem.parent_data.article_url !== undefined)? (
                                                        <div className="info-item">
                                                            <span className="ii-title">Source:</span> <br />
                                                            <span className="link" onClick={() => this.handleShowSourceURL("sourceurl-" + entailItem.claim_id)}>View source (URL)</span>
                                                        </div>
                                                    ) : null }

                                                    <div className="info-item">
                                                        <span className="ii-title">Description:</span> <br />
                                                        <span className="link" onClick={() => this.handleShowSourceDescription("description-" + entailItem.claim_id)}>View</span>
                                                    </div>
                                                </div>
                                                <div className="more-info">

                                                    <div id={"description-" + entailItem.claim_id} className="description">
                                                        <div className="text-background">
                                                            {entailItem.parent_data.article_description}
                                                        </div>
                                                        <div className="tooltip-arrow-container">
                                                            <img className="tooltip-arrow" src="/images/tooltip-arrow.png" alt="tooltip arrow" />
                                                        </div>
                                                    </div>

                                                    <div id={"sourceurl-" + entailItem.claim_id} className="sourceurl">
                                                        <div className="text-background">
                                                            <a href={entailItem.parent_data.article_url} target="_blank" rel="noreferrer nofollow" >{entailItem.parent_data.article_url}</a>
                                                        </div>
                                                        <div className="tooltip-arrow-container">
                                                            <img className="tooltip-arrow" src="/images/tooltip-arrow.png" alt="tooltip arrow" />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="tab-content refute">
                                    <div className="item no-evidence">No evidence found</div>
                                </div>
                            )
                        ) : null}

                    </div>

                </div>
            )
        } else {
            return (
                <Generic>
                    <Loading />
                </Generic>
            )
        }
    }
}
export default Evidence;
