import React from "react";
import Internal from "../templates/internal/internal";
const Privacy = () => {
    return (
        <Internal>
            <div className="privacy-component">
                <h1>Privacy</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mauris a diam maecenas sed enim. Quis auctor elit sed vulputate mi sit amet. Elementum tempus egestas sed sed risus pretium quam vulputate dignissim. Eget velit aliquet sagittis id consectetur. Nec nam aliquam sem et tortor consequat. Habitasse platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim. Sagittis vitae et leo duis ut diam quam nulla. Pharetra magna ac placerat vestibulum lectus mauris ultrices eros. Diam quis enim lobortis scelerisque fermentum. Viverra ipsum nunc aliquet bibendum enim. Pharetra diam sit amet nisl suscipit adipiscing bibendum est ultricies.</p>

                <p>Metus vulputate eu scelerisque felis imperdiet proin. Nisl tincidunt eget nullam non nisi est sit. Amet mattis vulputate enim nulla. Mauris commodo quis imperdiet massa. Iaculis urna id volutpat lacus laoreet. Phasellus faucibus scelerisque eleifend donec pretium vulputate sapien. Pretium nibh ipsum consequat nisl vel pretium lectus. Justo laoreet sit amet cursus sit amet dictum sit amet. Venenatis cras sed felis eget. Ut pharetra sit amet aliquam. Porta nibh venenatis cras sed felis eget velit aliquet sagittis. Elementum facilisis leo vel fringilla est ullamcorper eget. Viverra adipiscing at in tellus integer. Velit aliquet sagittis id consectetur purus ut.</p>

                <p>Sed augue lacus viverra vitae congue. Scelerisque felis imperdiet proin fermentum leo. Viverra maecenas accumsan lacus vel. Sed augue lacus viverra vitae congue eu consequat ac felis. Sed lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi. Suspendisse interdum consectetur libero id faucibus. Id volutpat lacus laoreet non curabitur gravida. Sit amet nulla facilisi morbi tempus iaculis urna id volutpat. Iaculis at erat pellentesque adipiscing commodo elit at imperdiet dui. Laoreet id donec ultrices tincidunt arcu.</p>

                <p>Ultricies tristique nulla aliquet enim tortor at auctor. Enim eu turpis egestas pretium. Cras ornare arcu dui vivamus arcu felis. Fermentum odio eu feugiat pretium. Enim ut sem viverra aliquet eget sit. Gravida cum sociis natoque penatibus et magnis dis. Sem et tortor consequat id porta. Ullamcorper morbi tincidunt ornare massa eget. Sit amet nisl purus in. Ultrices in iaculis nunc sed augue lacus viverra vitae congue. Risus sed vulputate odio ut enim blandit volutpat maecenas volutpat. Sit amet commodo nulla facilisi. Auctor eu augue ut lectus arcu bibendum at varius. Quis hendrerit dolor magna eget est lorem. Netus et malesuada fames ac turpis egestas. Tortor consequat id porta nibh venenatis cras sed. Dignissim suspendisse in est ante in nibh mauris cursus. Malesuada proin libero nunc consequat interdum varius.</p>

                <p>Lobortis scelerisque fermentum dui faucibus in. Sit amet aliquam id diam maecenas ultricies mi eget mauris. Viverra mauris in aliquam sem fringilla ut morbi. Tellus cras adipiscing enim eu turpis egestas pretium aenean pharetra. Ornare aenean euismod elementum nisi quis eleifend. Vitae nunc sed velit dignissim sodales ut. Auctor neque vitae tempus quam pellentesque nec nam. Proin sagittis nisl rhoncus mattis rhoncus urna neque. In hac habitasse platea dictumst quisque sagittis purus. Urna condimentum mattis pellentesque id nibh tortor. Mi proin sed libero enim. Elit sed vulputate mi sit amet mauris commodo quis. Non diam phasellus vestibulum lorem sed risus ultricies. Odio euismod lacinia at quis risus. Fermentum dui faucibus in ornare quam viverra orci. Risus feugiat in ante metus dictum at tempor. Justo laoreet sit amet cursus sit amet. Enim ut sem viverra aliquet eget sit. Eu augue ut lectus arcu.</p>
            </div>
        </Internal>
    )
}
export default Privacy;
