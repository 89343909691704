import React from "react";
import './internal.css';
import Generic from "../generic/generic";
import Header from "../../common/Header/header";
class Internal extends React.Component {
    render() {
        return (
            <Generic>
                <div className="internal-header-container">
                <Header/>
                </div>
                <div className="internal-page-template">
                    <div className="inner">
                        {this.props.children}
                    </div>
                </div>
            </Generic>
        )
    }
}
export default Internal;
