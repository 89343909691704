import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
    createBrowserRouter,
    RouterProvider,
    Navigate,
} from "react-router-dom";
import Home from './components/home/home';
import Article from "./components/article/article";
import About from "./components/about/about";
import Technology from "./components/technology/technology";
import Contact from "./components/contact/contact";
import Newsroom from "./components/newsroom/newsroom";
import Privacy from "./components/privacy/privacy";
import Conversation from "./components/chatgpt/conversation";
import Account from "./components/account/account";
import Auth from "./components/account/auth";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "article",
        element: <Article />,
    },
    {
        path: "chatgpt",
        element: <Conversation />,
    },
    {
        path: "about",
        element: <About />,
    },
    {
        path: "technology",
        element: <Technology />,
    },
    {
        path: "contact",
        element: <Contact />,
    },
    {
        path: "newsroom",
        element: <Newsroom />,
    },
    {
        path: "privacy-policy",
        element: <Privacy />,
    },
    {
        path: "account",
        element: <Account />,
    },
    {
        path: "auth",
        element: <Auth />,
    },
    {
        path: "*",
        element: <Navigate to={"/"} />,
    }
]);
ReactDOM.createRoot(document.getElementById("root")).render(
    <RouterProvider router={router} />
);

