
class Api {

    // API calls
    // POST
    static postData(url = ``, data = {}) {
        // Default options are marked with *
        return fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "omit", // include, same-origin, *omit
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Origin": "http://localhost:3000/",
                "Access-Control-Request-Method": "POST",
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
            .then(response => {
                return response.json();
            }) // parses response to JSON
            .catch(error => console.error(`Fetch Error =\n`, error));
    };
    static postAuthorizedData(url = ``, data = {}, authToken = ``) {
        // Default options are marked with *
        return fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, same-origin, *omit
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Token " + authToken
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer",
            body: JSON.stringify(data)
        })
            //.then(response => response.json()) // parses response to JSON
            .catch(error => console.error(`Fetch Error =\n`, error));
    };
    static putAuthorizedData(url = ``, data = {}, authToken = ``) {
        // Default options are marked with *
        return fetch(url, {
            method: "PUT", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, same-origin, *omit
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Token " + authToken
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer",
            body: JSON.stringify(data)
        })
            //.then(response => response.json()) // parses response to JSON
            .catch(error => console.error(`Fetch Error =\n`, error));
    };

    // GET
    static getAuthorizedData(url = ``, data = {}, authToken = ``) {
        // Default options are marked with *
        return fetch(url, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, same-origin, *omit
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Token " + authToken
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer"
        })
            .then(response => {
                //console.log(response);
                return response.json();
            }) // parses response to JSON
            .catch(error => console.error(`Fetch Error =\n`, error));
    };


    // DELETE
    static deleteAuthorizedData = (url = ``, data = {}, authToken = ``) => {
        // Default options are marked with *
        return fetch(url, {
            method: "DELETE", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, same-origin, *omit
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Token " + authToken
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", //no-referrer, *client
            body: JSON.stringify(data)
        }) .then(response => {
            //console.log(response);
            return response.json();
        }) // parses response to JSON
        .catch(error => console.error(`Fetch Error =\n`, error));
    };

}
export default Api;
